import React, { useState } from "react";
import Modal from "react-modal";

// Define the modal and overlay styles
const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    backgroundColor: "#231E3D",
    color: "white",
    borderRadius: "8px",
    padding: "20px",
    width: "80%",
    maxWidth: "600px",
    margin: "auto",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: "auto",
  },
};

function Apresentation() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleProsseguir = async () => {
    setIsLoading(true);

    try {
      const response = await fetch("https://104.234.189.58:3005/api/payment/register", {
        method: "POST",
      });
      const data = await response.json();

      if (data.encodedImage) {
        setQrCodeImage(data.encodedImage);
      } else {
        console.error("QR Code não encontrado na resposta");
      }
    } catch (error) {
      console.error("Erro ao obter o QR Code:", error);
    } finally {
      setIsLoading(false);
      setIsQRCodeModalOpen(true);
    }
  };

  return (
    <div className="bg-[#231E3D] pt-14 lg:px-28 px-10 pb-10">
      <h1 className="text-white text-4xl font-semibold pt-3 text-center">
        <a style={{ color: "#ff6f00" }}>RECEBA R$24,80</a> APÓS BATER A META DE R$1.000 FATURADOS!
      </h1>
      <p className="text-white font-semibold pt-3 text-center">
        Cadastre-se agora por apenas R$12,90 e tenha acesso a todos os nossos serviços! Ao atingir a meta de R$1.000 em faturamento, <br /> você receberá um bônus de R$24,80.
      </p>
      <h1 className="text-white text-4xl font-semibold pt-3 text-center">
        <a style={{ color: "#ff6f00" }}>Nós pagamos você</a> para se cadastrar!
      </h1>
      <div className="flex items-center justify-center mt-10">
        <a
          href="#"
          onClick={openModal}
          className="rounded px-7 py-3 bg-[#ff6f00] text-white hover:text-white overflow-hidden c-btn tracking-wide"
        >
          Se cadastre agora!
        </a>
      </div>

      {/* Modal Principal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Modal de Cadastro"
      >
        <h2 className="text-2xl font-bold mb-4">
          Seja bem-vindo (a) a <a style={{ color: "#ff6f00" }}>QuacPay</a>
        </h2>

        <p className="text-white mb-4">
          Ao se cadastrar, você pagará apenas R$12,90 e terá acesso a todos os nossos serviços. Após atingir a meta de R$1.000 em faturamento, você receberá um bônus de R$24,80.
        </p>

        <p className="text-white mb-6 font-semibold">
          Este ambiente é totalmente seguro e controlado pela QuacPay.
        </p>

        <div className="flex justify-center space-x-4">
          <button
            onClick={closeModal}
            className="rounded px-7 py-3 bg-[#A9A9A9] text-white hover:text-white"
          >
            Fechar
          </button>
          <button
            onClick={handleProsseguir}
            className="rounded px-7 py-3 bg-[#ff6f00] text-white hover:text-white"
          >
            Prosseguir
          </button>
        </div>
      </Modal>

      {/* Modal de QR Code */}
      <Modal
        isOpen={isQRCodeModalOpen}
        onRequestClose={() => setIsQRCodeModalOpen(false)}
        style={modalStyles}
        contentLabel="QR Code"
      >
        <h2 className="text-2xl font-bold mb-4">Transação para Abertura de Conta</h2>

        <div className="flex flex-col justify-center items-center">
          {isLoading ? (
            <div className="flex flex-col justify-center items-center">
              <div className="loader"></div>
              <p className="text-white mt-4">Estamos emitindo o QR Code para pagamento...</p>
            </div>
          ) : qrCodeImage ? (
            <>
              <img
                src={`data:image/png;base64,${qrCodeImage}`}
                alt="QR Code"
                style={{ width: "200px", height: "200px", marginBottom: "20px" }}
              />
              <p className="text-white font-semibold mb-4">
                Esta transação é totalmente segura e gerenciada pela <a style={{ color: "#ff6f00" }}>QuacPay</a>, garantindo a responsabilidade e proteção total na abertura da sua conta.
              </p>
              <p className="text-white mb-6">
                Realize o pagamento para dar início à abertura de sua conta e acessar todos os nossos serviços. Com apenas R$12,90, você estará habilitado para faturar e conquistar o bônus de R$24,80 ao atingir a meta de R$1.000!
              </p>

              <p className="text-white mb-2">
                Após o pagamento, você será redirecionado para enviar os seus dados.
              </p>

            </>
          ) : (
            <p className="text-white">Erro ao carregar o QR Code</p>
          )}
        </div>

        <div className="flex justify-center space-x-4 mt-4">
          <button
            onClick={() => setIsQRCodeModalOpen(false)}
            className="rounded px-7 py-3 bg-[#A9A9A9] text-white hover:text-white"
          >
            Fechar
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Apresentation;
