// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Default styling for the mascot */\r\n.Header_mascote__-Ryxb {\r\n    width: 600px;\r\n    margin-top: -80px;\r\n  }\r\n  \r\n  /* Responsiveness for 1920x1080 */\r\n  @media screen and (min-width: 1920px) and (max-height: 1080px) {\r\n    .Header_mascote__-Ryxb {\r\n      margin-top: 20px; /* Adjust downward */\r\n    }\r\n  }\r\n\r\n  /* Hide mascot on mobile devices */\r\n@media screen and (max-width: 768px) {\r\n    .Header_mascote__-Ryxb {\r\n      display: none; /* Hide mascot */\r\n    }\r\n  }\r\n  ", "",{"version":3,"sources":["webpack://./src/Components/Header.module.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,YAAY;IACZ,iBAAiB;EACnB;;EAEA,iCAAiC;EACjC;IACE;MACE,gBAAgB,EAAE,oBAAoB;IACxC;EACF;;EAEA,kCAAkC;AACpC;IACI;MACE,aAAa,EAAE,gBAAgB;IACjC;EACF","sourcesContent":["/* Default styling for the mascot */\r\n.mascote {\r\n    width: 600px;\r\n    margin-top: -80px;\r\n  }\r\n  \r\n  /* Responsiveness for 1920x1080 */\r\n  @media screen and (min-width: 1920px) and (max-height: 1080px) {\r\n    .mascote {\r\n      margin-top: 20px; /* Adjust downward */\r\n    }\r\n  }\r\n\r\n  /* Hide mascot on mobile devices */\r\n@media screen and (max-width: 768px) {\r\n    .mascote {\r\n      display: none; /* Hide mascot */\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mascote": "Header_mascote__-Ryxb"
};
export default ___CSS_LOADER_EXPORT___;
