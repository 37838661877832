import React from "react";
import Fade from "react-reveal/Fade";

function Services() {
  return (
    <div className="bg-[#231E3D] pt-14 lg:px-28 px-10 pb-10" id="services">
      <Fade bottom cascade>
        <div className="w-10 h-1 from-[#ff6f00] to-[#BD4FF1] bg-gradient-to-r"></div>
        <h1 className="text-white text-4xl font-semibold pt-3">Nossos Serviços</h1>
        <p className="text-[#696984] font-semibold pt-4 sm:whitespace-normal whitespace-normal">
          Nossos serviços são de alta qualidade e oferecem soluções personalizadas para
          atender aos requisitos de seus clientes.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-10 lg:pb-20">
          <div className="p-8 bg-[#ff6f00] flex text-white justify-between font-bold cursor-pointer relative group">
            Emissão de Boletos
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
          <div className="p-8 bg-[#ff6f00] flex text-white justify-between cursor-pointer font-bold">
            Integração API REST
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
          <div className="p-8 bg-[#ff6f00] flex text-white justify-between cursor-pointer font-bold">
            Gestão de Assinaturas e Recorrência
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
          <div className="p-8 bg-[#ff6f00] flex text-white justify-between cursor-pointer font-bold">
            Cobrança de Multas e Impostos
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
          <div className="p-8 bg-[#ff6f00] flex text-white justify-between cursor-pointer font-bold">
            Análise de Crédito e Risco
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
          <div className="p-8 bg-[#ff6f00] flex text-white justify-between cursor-pointer font-bold">
            Envio de Notificações e Lembretes
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
          <div className="p-8 bg-[#ff6f00] flex text-white justify-between cursor-pointer font-bold">
            Parcelamento de Débitos
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
          <div className="p-8 bg-[#ff6f00] flex text-white justify-between cursor-pointer font-bold">
            Geração de Relatórios Financeiros
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
          <div className="p-8 bg-[#ff6f00] flex text-white justify-between cursor-pointer font-bold">
            Checkout Automatizado
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Services;
